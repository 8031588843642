import React, { createContext, useEffect, useState } from "react"
import { useTransform, useScroll } from "framer-motion"

const StickyHeaderContext = createContext({
  isStuck: false,
})

const StickyHeaderProvider = ({ children }) => {
  const [sticky, setSticky] = useState(false)

  const { scrollY } = useScroll()
  const yRange = useTransform(scrollY, [0, 50], [0, 1])

  useEffect(() => {
    // `scrollY.current` should be used here, but it returns `0`
    // on safari when using an anchor link
    setSticky(scrollY.current >= 50)
    return yRange.onChange((v) => setSticky(v >= 1))
  }, [yRange])

  return (
    <StickyHeaderContext.Provider
      value={{
        isStuck: sticky,
      }}
    >
      {children}
    </StickyHeaderContext.Provider>
  )
}

export default StickyHeaderContext

export { StickyHeaderProvider }
