import React, { createContext, useState } from "react"

const ActiveRouteContext = createContext({
  section: null,
  setSection: () => {},
  resetSection: () => {},
})

const ActiveRouteProvider = ({ children }) => {
  const [currentSection, setCurrentSection] = useState(null)

  function resetSection() {
    setCurrentSection(null)
  }

  return (
    <ActiveRouteContext.Provider
      value={{
        section: currentSection,
        setSection: setCurrentSection,
        resetSection,
      }}
    >
      {children}
    </ActiveRouteContext.Provider>
  )
}

export default ActiveRouteContext

export { ActiveRouteProvider }
