import "./src/styles/fonts.css"
import "./src/styles/global.css"
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/grid"

import "requestidlecallback-polyfill"
import React from "react"
import { CouponProvider } from "context/coupon-context"
import { GeoProvider } from "context/geo-context"
import { StickyHeaderProvider } from "context/sticky-header-context"
import { ActiveRouteProvider } from "context/active-route-context"

export const wrapRootElement = ({ element }) => (
  <GeoProvider>
    <CouponProvider>
      <StickyHeaderProvider>
        <ActiveRouteProvider>{element}</ActiveRouteProvider>
      </StickyHeaderProvider>
    </CouponProvider>
  </GeoProvider>
)

export const onRouteUpdate = ({ location }) => {
  if (window) {
    // Use a small delay to allow React to update the document
    setTimeout(() => {
      // Fire Google Tag Manager tracking event
      if (process.env.GATSBY_GTM_ID && typeof window.dataLayer != "undefined") {
        window.dataLayer.push({
          event: "Pageview",
          pagePath: location.pathname,
          pageTitle: document.title,
        })
      }
      // Fire Hubspot tracking event
      if (process.env.GATSBY_HUBSPOT_ID && typeof window._hsq != "undefined") {
        window._hsq.push(["setPath", location.pathname])
        window._hsq.push(["trackPageView"])
      }
    }, 5)
  }
}

// Allow to imperatively navigate without updating scroll position
export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps?.location?.state) {
    const { disableScrollUpdate, scrollTo } = routerProps.location.state
    if (scrollTo) {
      window.scrollTo(scrollTo.x, scrollTo.y)
      return false
    }
    if (disableScrollUpdate) {
      return !disableScrollUpdate
    }
  } else if (routerProps?.location?.hash) {
    window.requestIdleCallback(() => {
      const elem = document.querySelector(routerProps.location.hash)
      if (elem) {
        elem.scrollIntoView()
      }
    })
    return false
  } else {
    return false
  }
}
