import React, { useEffect, useState } from "react"

const defaultState = {
  geo: null,
  get: () => {},
}

const GeoContext = React.createContext(defaultState)

const GeoProvider = ({ children }) => {
  const [geoData, setGeoData] = useState(null)

  const get = async (force = false) => {
    if (!geoData) {
      const url = `/js/geo.json`
      const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
      })
      const body = await response.json()
      if (body.code) {
        setGeoData(body)
        return body
      }
    } else {
      return geoData
    }
  }

  useEffect(() => {
    get()
  }, [])

  return (
    <GeoContext.Provider
      value={{
        geoData,
        get,
      }}
    >
      {children}
    </GeoContext.Provider>
  )
}

export default GeoContext

export { GeoProvider }
