exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-attention-economy-js": () => import("./../../../src/pages/attention-economy.js" /* webpackChunkName: "component---src-pages-attention-economy-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-insights-premium-js": () => import("./../../../src/pages/insights/premium.js" /* webpackChunkName: "component---src-pages-insights-premium-js" */),
  "component---src-pages-measurement-js": () => import("./../../../src/pages/measurement.js" /* webpackChunkName: "component---src-pages-measurement-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-demand-360-js": () => import("./../../../src/pages/products/demand360.js" /* webpackChunkName: "component---src-pages-products-demand-360-js" */),
  "component---src-pages-products-demand-360-lite-js": () => import("./../../../src/pages/products/demand360/lite.js" /* webpackChunkName: "component---src-pages-products-demand-360-lite-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-start-2-js": () => import("./../../../src/pages/start/2.js" /* webpackChunkName: "component---src-pages-start-2-js" */),
  "component---src-pages-start-3-js": () => import("./../../../src/pages/start/3.js" /* webpackChunkName: "component---src-pages-start-3-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-board-member-js": () => import("./../../../src/templates/board-member.js" /* webpackChunkName: "component---src-templates-board-member-js" */),
  "component---src-templates-demand-360-product-js": () => import("./../../../src/templates/demand360-product.js" /* webpackChunkName: "component---src-templates-demand-360-product-js" */),
  "component---src-templates-demand-academy-post-js": () => import("./../../../src/templates/demand-academy-post.js" /* webpackChunkName: "component---src-templates-demand-academy-post-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-group-js": () => import("./../../../src/templates/product-group.js" /* webpackChunkName: "component---src-templates-product-group-js" */)
}

