import React, { useEffect, useState, useRef } from "react"

import { setWithExpiry, getWithExpiry } from "utils/storage"

const defaultState = {
  coupon: null,
  partnerId: null,
  applyCoupon: () => {},
  couponApplies: () => {},
  getCoupon: () => {},
  getCouponCode: () => {},
  setCoupon: () => {},
  checkCoupon: () => {},
  getSpecialOffer: () => {},
}

const CouponContext = React.createContext(defaultState)

const isBrowser = typeof window !== "undefined"

const CouponProvider = ({ children }) => {
  const [coupon, setCouponState] = useState(null)
  const [partnerId, setPartnerId] = useState(null)
  const [couponData, setCouponData] = useState(null)

  const loading = useRef(false)

  const getParams = () => {
    getCouponCode()
  }

  const getCouponCode = () => {
    // Get details from query string or localStorage
    // Save to context and localStorage
    if (isBrowser) {
      const urlParams = new URLSearchParams(window.location.search)

      const coupon = urlParams.get("coupon") || getWithExpiry("coupon")
      if (coupon?.length > 0) {
        setCouponState(coupon)
        if (urlParams.get("coupon")) {
          setWithExpiry("coupon", coupon, 2592000)
        }
      }

      const partnerId = urlParams.get("partner") || getWithExpiry("partner")
      if (partnerId?.length > 0) {
        setPartnerId(partnerId)
        if (urlParams.get("partner")) {
          setWithExpiry("partnerId", partnerId, 2592000)
        }
      }

      return coupon
    }

    return null
  }

  const getCoupon = async (force = false) => {
    if (loading.current) {
      return null
    }

    getParams()
    if (couponData !== null && !force && couponData.id === coupon) {
      return couponData
    }
    if (coupon) {
      const url = `${process.env.GATSBY_MONITOR_API}/coupon/${coupon}`

      loading.current = true

      const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": process.env.GATSBY_STRIPE_KEY,
        },
      })

      loading.current = false

      const body = await response.json()
      
      if (body.coupon) {
        setCouponData(body.coupon)
        return body.coupon
      }
    }

    return null
  }

  const getSpecialOffer = async () => {
    const coupon = await getCoupon()

    if (!coupon) {
      return null
    }

    const {
      offer_title,
      offer_description,
      offer_terms,
      offer_cta,
      plan_applicable,
    } = coupon?.metadata || {}

    if (
      offer_title &&
      offer_description &&
      offer_terms &&
      offer_cta &&
      plan_applicable
    ) {
      return {
        title: offer_title,
        description: offer_description,
        terms: offer_terms,
        cta: offer_cta,
        applicablePlan: plan_applicable,
      }
    }

    return null
  }

  const setCoupon = (coupon) => {
    setCouponState(coupon)
    setWithExpiry("coupon", coupon, 2592000)
  }

  // Only checks the coupon validity
  const checkCoupon = async () => {
    const coupon = await this.getCoupon()
    return coupon?.valid === true
  }

  // Checks coupon against plan
  const couponApplies = (toPlan) => {
    let planOK = false
    if (couponData?.metadata?.plan_applicable) {
      if (
        (typeof couponData.metadata.plan_applicable === "string" &&
          couponData.metadata.plan_applicable === toPlan.id) ||
        couponData?.metadata?.plan_applicable?.toLowerCase() ===
          toPlan?.nickname?.toLowerCase()
      ) {
        planOK = true
      } else if (
        typeof couponData.metadata.plan_applicable === "object" &&
        (couponData.metadata.plan_applicable.includes(toPlan.id) ||
          couponData.metadata.plan_applicable
            .map((p) => p.toLowerCase())
            .includes(toPlan.nickname.toLowerCase()))
      ) {
        planOK = true
      }
    } else {
      planOK = true
    }
    return planOK && couponData?.valid
  }

  // Return plan price with coupon applied
  const applyCoupon = (plan, period) => {
    const amount =
      period === "month"
        ? (plan.interval === "year"
            ? plan.amount_decimal / 12
            : plan.amount_decimal) / 100
        : (plan.interval === "month"
            ? plan.amount_decimal * 12
            : plan.amount_decimal) / 100
    if (!couponApplies(plan)) {
      return amount
    }
    if (couponData?.percent_off) {
      if (couponData?.duration === "forever") {
        return amount * ((100 - couponData.percent_off) / 100)
      } else if (couponData?.duration === "repeating") {
        if (period === "month") {
          return amount * ((100 - couponData.percent_off) / 100)
        } else {
          return (
            (amount / 12) *
              ((100 - couponData.percent_off) / 100) *
              couponData?.duration_in_months +
            (amount / 12) * (12 - couponData?.duration_in_months)
          )
        }
      } else if (couponData?.duration === "once") {
        if (plan.interval === "year" || period === "month") {
          return amount * ((100 - couponData.percent_off) / 100)
        } else {
          return (
            (amount / 12) * ((100 - couponData.percent_off) / 100) +
            (amount / 12) * 11
          )
        }
      }
    } else if (couponData?.amount_off) {
      if (couponData?.duration === "forever") {
        if (plan.interval === "month") {
          if (period === "month") {
            return Math.max(0, amount - couponData.amount_off / 100)
          } else {
            return Math.max(0, amount / 12 - couponData.amount_off / 100) * 12
          }
        } else {
          if (period === "month") {
            return Math.max(0, amount * 12 - couponData.amount_off / 100) / 12
          } else {
            return Math.max(0, amount - couponData.amount_off / 100)
          }
        }
      } else if (couponData?.duration === "once") {
        if (plan.interval === "month") {
          if (period === "month") {
            return Math.max(0, amount - couponData.amount_off / 100)
          } else {
            return (
              Math.max(0, amount / 12 - couponData.amount_off / 100) +
              (amount / 12) * 11
            )
          }
        } else if (plan.interval === "year") {
          if (period === "month") {
            return Math.max(0, (amount * 12 - couponData.amount_off / 100) / 12)
          } else {
            return Math.max(0, amount - couponData.amount_off / 100)
          }
        }
      } else if (couponData?.duration === "repeating") {
        if (plan.interval === "month") {
          if (period === "month") {
            return Math.max(0, amount - couponData.amount_off / 100)
          } else {
            return (
              Math.max(0, amount / 12 - couponData.amount_off / 100) *
                couponData.duration_in_months +
              (amount / 12) * Math.max(12 - couponData.duration_in_months, 0)
            )
          }
        } else {
          if (period === "month") {
            return Math.max(0, amount * 12 - couponData.amount_off / 100) / 12
          } else {
            return Math.max(0, amount - couponData.amount_off / 100)
          }
        }
      }
    } else {
      return amount
    }
  }

  useEffect(() => {
    getParams()
  })

  return (
    <CouponContext.Provider
      value={{
        coupon,
        partnerId,
        applyCoupon: applyCoupon,
        couponApplies: couponApplies,
        getCoupon: getCoupon,
        getCouponCode: getCouponCode,
        setCoupon: getCoupon,
        checkCoupon: checkCoupon,
        getSpecialOffer: getSpecialOffer,
      }}
    >
      {children}
    </CouponContext.Provider>
  )
}

export default CouponContext

export { CouponProvider }
